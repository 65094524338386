import React from "react";

export default function DataDeletion() {
  return (
    <div className="flex items-center justify-center h-screen w-full">
      <div className="w-[90%] sm:w-1/2 bg-white rounded-lg p-10">
        <div className="flex items-center justify-between">
          <h1 className="font-black text-[20px]">DATA DELETION</h1>
          <img
            className="w-[40%] md:w-[120px] lg:w-[120px] object-contain"
            src="/niuzie-full-logo.png"
            alt="niuzie logo"
          />
        </div>
        <hr className="mt-4" />
        <h1 className="font-bold text-gray-600 mt-8">
          How to delete you account and data.
        </h1>
        <p className="mt-2 text-gray-500">
          To request a deletion of your account and data, send an SMS with the
          mobile number you used to register your account to{" "}
          <a
            className="underline text-orange-500"
            href="sms:+254750925599"
            target="_blank"
          >
            +254750925599
          </a>
        </p>
        <h1 className="font-bold text-gray-600 mt-4">Data deleted</h1>
        <ul className="text-gray-500 text-sm mt-2">
          <li>1. First Name</li>
          <li>2. Last Name</li>
          <li>3. Email</li>
          <li>4. Phone Number</li>
          <li>5. All products you've created</li>
          <li>6. Your reviews on products</li>
        </ul>
        <h1 className="font-bold text-gray-600 mt-4">Retained Data</h1>
        <p className="mt-2 text-gray-500">
          None of your data is retained when your account is deleted
        </p>
      </div>
    </div>
  );
}
