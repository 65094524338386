import { animateScroll as scroll } from "react-scroll";
import Intro from "../components/Intro";
import Header from "../components/Header";
import AboutUs from "../components/AboutUs";
import How from "../components/How";
import Pricing from "../components/Pricing";
import Faqs from "../components/Faqs";
import ContactUs from "../components/ContactUs";

function Landing() {
  const scrollToSection = (sectionId) => {
    scroll.scrollTo(sectionId, {
      duration: 500,
      smooth: true,
      offset: -70,
      spy: true,
      activeClass: "active",
    });
  };
  return (
    <div className="bg-dark h-full text-gray-300">
      <Header id="intro" scrollToSection={scrollToSection} />
      <Intro id="intro" />
      <AboutUs id="about-us" data-testid="about-us-section" />
      <How id="how-it-works" />
      <Pricing id="pricing" />
      <Faqs id="faqs" />
      <ContactUs id="contact-us" />
    </div>
  );
}

export default Landing;
