import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { animateScroll as scroll } from "react-scroll";
import Landing from "./pages/landing";
import DataDeletion from "./pages/data-deletion";

function App() {
  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: <Landing />,
    },
    {
      path: "data-deletion",
      element: <DataDeletion />,
    },
  ]);

  return <RouterProvider router={appRouter} />;
}

export default App;
